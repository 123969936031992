import React, { ReactNode, useRef, useState } from 'react';
import theme from '../../../../../theme';
import { useTranslation } from 'react-i18next';
import { useSelector } from '../../../../../hooks/redux';
import { selectIssueDetails, selectIssueSeverities } from '../../../../../store/selectors/issue';
import CollapsibleTable, { TColumn, TData } from '../../../../../components/CollapsibleTable';
import { Link, styled } from '@mui/material';
import { getFormattedDate, getFormattedTime, omit } from '../../../../../helpers';
import { NA_PLACEHOLDER, routes, sortOptions } from '../../../../../constants';
import Sort from '../../../../../components/Sort';
import Filter from '../../../../../components/Filter';
import Chart from '../../../../../components/Chart';
import { ISSUE_STATUS, SORT_ORDER, TChartData, TFilterSelect, TMeasurementEvent } from '../../../../../types';
import { v4 as uuidv4 } from 'uuid';

/* ------- Styles ------- */
const DetailsHeader = styled('h3')({
  marginTop: '5px',
  fontSize: '20px',
});

const SortFilterContainer = styled('div')({
  margin: '15px 0 24px 0',
  display: 'flex',
  gap: '24px',
});

/* ------- Types ------- */
type THistoryEvent = {
  id: string;
  eventType: string;
  date: string;
  time: string;
  severity: ReactNode | string;
  actions: ReactNode | null;
  groupedItems?: THistoryEvent[];
};

interface IEventHistory {
  tabPanelHeight: number;
}

interface IEventFilter {
  [key: string]: string[];
}

type THistoryEventsGrouper = (eventHistory: TData[], groupEventType: string, eventTypeForGroups: string) => TData[];
type THistoryEventsFormatter = (eventHistory: TData[]) => TData[];

/* ------- Components & Functions ------- */
export const groupMeasurementEvents: THistoryEventsGrouper = (eventHistory, groupEventType, eventTypeForGroups) => {
  const result: TData[] = [];
  let tempGroup: TData[] = [];

  eventHistory.forEach((item) => {
    if (item.eventType !== eventTypeForGroups) {
      // if tempGroup is not empty, push it to the result array. If only one item in tempGroup, push it to the result as single item
      if (tempGroup.length > 1) {
        result.push({
          eventType: `${groupEventType} (${tempGroup.length})`,
          date: tempGroup[0].date,
          time: tempGroup[0].time,
          severity: '',
          actions: null,
          groupedItems: tempGroup,
        });
      } else if (tempGroup.length === 1) {
        result.push(tempGroup[0]);
      }

      // reset tempGroup and push item that is not a measurement to the result
      tempGroup = [];
      result.push(item);
    }

    // if item is a measurement, push it to the tempGroup
    if (item.eventType === eventTypeForGroups) {
      tempGroup.push(item);
    }
  });

  // in case of last item is a measurement push tempGroup to the result, after the loop
  if (tempGroup.length > 1) {
    result.push({
      eventType: `${groupEventType} (${tempGroup.length})`,
      date: tempGroup[0].date,
      time: tempGroup[0].time,
      severity: '',
      actions: null,
      groupedItems: tempGroup,
    });
  } else if (tempGroup.length === 1) {
    result.push(tempGroup[0]);
  }

  return result;
};

export const formatHistoryEventDateTime: THistoryEventsFormatter = (historyEvents) => {
  historyEvents.forEach((item) => {
    item.date = getFormattedDate(item.date as string);
    item.time = getFormattedTime(item.time as string);

    if (item.groupedItems) {
      item.groupedItems.forEach((groupItem) => {
        groupItem.date = getFormattedDate(groupItem.date as string);
        groupItem.time = getFormattedTime(groupItem.time as string);
      });
    }
  });

  return historyEvents;
};

const EventHistory: React.FC<IEventHistory> = ({ tabPanelHeight }) => {
  const { t } = useTranslation();
  const details = useSelector(selectIssueDetails);
  const severityOptions = useSelector(selectIssueSeverities);

  const [expandedRows, setExpandedRows] = useState<string[]>([]);
  const [showChart, setShowChart] = useState<TChartData | null>(null);
  const [eventSort, setEventSort] = useState(SORT_ORDER.DESCENDING);
  const [eventFilter, setEventFilter] = useState<IEventFilter>({ severityLevel: [], eventType: [] });
  const isFiltered = !!eventFilter.severityLevel.length || !!eventFilter.eventType.length;

  const headlineRef = useRef<HTMLDivElement>(null);
  // calc table height with tab headline height and 100px for margin and padding
  const tableHeight = tabPanelHeight - (headlineRef.current?.offsetHeight || 0) - 100;

  const createChartData: (eventData: TMeasurementEvent['eventData'] | null) => TChartData | null = (eventData) => {
    let chartData: TChartData = {
      ts: [],
      acc_z: [],
    };

    if (eventData?.[0]?.rawData) {
      chartData = { ...(omit(JSON.parse(eventData[0].rawData), ['serialnumber', 'mountposition']) as TChartData) };
    }

    return chartData;
  };

  const getActions: (eventType: string, eventData: TMeasurementEvent['eventData'] | null) => ReactNode | null = (
    eventType,
    eventData,
  ) => {
    const actions = {
      goToExportHistory: (
        <Link href={routes.EXPORT_HISTORY}>{t('issues:detailedView:eventHistory:actions:goToExportHistory')}</Link>
      ),
      viewDiagram: (
        <Link component='button' onClick={() => setShowChart(createChartData(eventData))}>
          {t('issues:detailedView:eventHistory:actions:viewDiagram')}
        </Link>
      ),
    };

    switch (eventType) {
      case 'Exported':
        return actions.goToExportHistory;
      case 'MeasurementEvent':
        return actions.viewDiagram;
      default:
        return null;
    }
  };

  const createData: (event: THistoryEvent) => TData = ({ id, eventType, date, time, severity, actions }) => {
    return {
      id,
      eventType,
      date,
      time,
      severity,
      actions,
    };
  };

  const generateRows: () => TData[] = () => {
    if (!details) {
      return [];
    }

    const { issueStatusChangeHistories, measurementEvents } = details;
    let historyEvents: TData[] = [];

    // create data for measurement events
    historyEvents = measurementEvents.map((event) =>
      createData({
        id: uuidv4(),
        eventType: t('issues:detailedView:eventHistory:eventType:' + event.__typename),
        date: event.measurementEventDateTime,
        time: event.measurementEventDateTime,
        severity: event.severityLevel ? (
          <span style={{ color: theme.severity[event.severityLevel], fontWeight: 600 }}>
            {t(`issues:severity:${event.severityLevel}`)}
          </span>
        ) : (
          NA_PLACEHOLDER
        ),
        actions: getActions(event.__typename, event.eventData),
      }),
    );

    // create data for status change events
    if (issueStatusChangeHistories) {
      historyEvents = [
        ...historyEvents,
        ...issueStatusChangeHistories.map((event) =>
          createData({
            id: uuidv4(),
            eventType: t('issues:detailedView:eventHistory:eventType:' + event.toStatus.description),
            date: event.changeDateTime,
            time: event.changeDateTime,
            severity: NA_PLACEHOLDER,
            actions: getActions(event.toStatus.description, null),
          }),
        ),
      ];
    }

    // group measurement events
    historyEvents = groupMeasurementEvents(
      historyEvents,
      t('issues:detailedView.eventHistory:eventType:MeasurementGroup'),
      t('issues:detailedView.eventHistory:eventType:MeasurementEvent'),
    );

    // filter list if event type filters applied
    if (eventFilter.eventType.length) {
      historyEvents = historyEvents.filter((event) =>
        event.groupedItems &&
        eventFilter.eventType.includes(t('issues:detailedView.eventHistory:eventType:MeasurementEvent'))
          ? true
          : eventFilter.eventType.includes(event.eventType as string),
      );
    }

    // filter list if severity filters applied
    if (eventFilter.severityLevel.length) {
      historyEvents = historyEvents.filter((event) =>
        event.severity === NA_PLACEHOLDER
          ? eventFilter.severityLevel.includes('Unclassified')
          : event.groupedItems?.some((item) =>
              eventFilter.severityLevel.includes(item.severity.props.children.toUpperCase() as string),
            ) || eventFilter.severityLevel.includes(event.severity.props.children.toUpperCase() as string),
      );
    }

    const sortFn = sortOptions.find((opt) => opt.value === eventSort)?.compareFn;

    if (sortFn) {
      historyEvents = historyEvents.sort((a, b) => sortFn(a.date, b.date));
    }

    // formate date and time for history events
    historyEvents = formatHistoryEventDateTime(historyEvents);

    return historyEvents;
  };

  const columns: readonly TColumn[] = [
    { id: 'eventType', label: t('issues:detailedView:eventHistory:eventType:title') },
    { id: 'date', label: t('issues:detailedView:eventHistory:date') },
    { id: 'time', label: t('issues:detailedView:eventHistory:time') },
    { id: 'severity', label: t('issues:severity:title') },
    { id: 'actions', label: t('issues:detailedView:eventHistory:actions:title') },
  ];

  const filters: TFilterSelect[] = [
    {
      key: 'eventType',
      label: 'Type',
      options: [
        // @ts-expect-error: Single usage of measurement event type
        { description: 'Measurement', id: 'measurement' },
        { description: ISSUE_STATUS.READ, id: 'read', __typename: 'IssueStatus' },
        { description: ISSUE_STATUS.EXPORTED, id: 'exported', __typename: 'IssueStatus' },
        { description: ISSUE_STATUS.MAINTENANCE_COMPLETED, id: 'maintenance_completed', __typename: 'IssueStatus' },
      ],
    },
    {
      key: 'severityLevel',
      label: 'Severity',
      // @ts-expect-error: Unclassified option for status change events
      options: [...severityOptions, { description: 'Unclassified', id: 'unclassified' }],
    },
  ];

  const handleRowExpandToggle = (id: string) => {
    if (expandedRows.includes(id)) {
      setExpandedRows(expandedRows.filter((row) => row !== id));
    } else {
      setExpandedRows([...expandedRows, id]);
    }
  };

  const eventHistorySortingOptions = sortOptions.map((opt) => ({
    ...opt,
    label: t(`common:sort:${opt.label}`),
  }));

  const getChartData = (chartData: TChartData) => {
    // remove timestring from data
    const filteredData = omit(chartData, ['ts']);

    // convert values from mili to g
    for (const [key, value] of Object.entries(filteredData)) {
      filteredData[key] = value.map((value: number) => value / 1000);
    }

    return filteredData;
  };

  return (
    <>
      <DetailsHeader ref={headlineRef}>{t('issues:detailedView:eventHistory:headline')}</DetailsHeader>
      <SortFilterContainer>
        <Sort
          icon={false}
          sortByLabel={t('common:sort:byDate')}
          options={eventHistorySortingOptions}
          selected={eventSort}
          onChange={(value) => setEventSort(value as SORT_ORDER)}
        />
        <Filter
          filters={filters}
          filterState={eventFilter}
          onApply={(updatedFilterState) => setEventFilter(updatedFilterState as IEventFilter)}
          onReset={() => setEventFilter({ severityLevel: [], eventType: [] })}
          isFiltered={isFiltered}
        />
      </SortFilterContainer>
      <CollapsibleTable
        columns={columns}
        rows={generateRows()}
        height={tableHeight}
        expandedRows={expandedRows}
        onExpandToggle={(id) => handleRowExpandToggle(id)}
      />
      {showChart && (
        <Chart
          title={t('issues:detailedView:overview:chart:title')}
          labels={showChart.ts}
          data={getChartData(showChart)}
          hiddenDatasets={['acc_x', 'acc_y', 'acc_z']}
          xAxisLabel={t('issues:detailedView:overview:chart:xLabel')}
          yAxisLabel={t('issues:detailedView:overview:chart:yLabel')}
          fullscreen
          onClose={() => setShowChart(null)}
        />
      )}
    </>
  );
};

export default EventHistory;
