import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from '../../../../../hooks/redux';
import { selectIssueCauses, selectIssueDetails } from '../../../../../store/selectors/issue';
import {
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Link,
  Typography,
  Chip,
  Box,
  Select,
  MenuItem,
  OutlinedInput,
  TextField,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getFormattedDate } from '../../../../../helpers';
import { SEVERITY_LEVEL, TChartData, TIssueCause, TIssueFeedback, TSeverityLevel } from '../../../../../types';
import Chart from '../../../../../components/Chart';
import { omit } from 'lodash';
import { fetchIssueFeedbacksPending, updateIssueFeedbacksPending } from '../../../../../store/slices/issues';
import MaintenanceHistorySort from './MaintenanceHistorySort';
import MaintenanceHistoryFilter from './MaintenanceHistoryFilter';
import dayjs from 'dayjs';

const Accordion = styled(MuiAccordion)(() => ({
  '&.Mui-expanded': {
    margin: 0,
  },
}));

const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
    '&.Mui-expanded': {
      margin: '12px 0 12px 8px',
    },
  },
  '&.Mui-expanded': {
    minHeight: 'auto',
    backgroundColor: theme.palette.grey[20],
  },
}));

const editMaintenance = {
  width: '33%',
  flexShrink: 0,
  textAlign: 'right',

  '&>*': {
    textAlign: 'right',

    '&:first-of-type': {
      marginRight: '16px',
    },
  },
};

const Detail = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '45%',
  flexShrink: 0,
});

const GridContainer = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridTemplateRows: 'min-content',
  gap: '16px 0px',
});

const GridItem = styled('div')({
  display: 'grid',
  gridTemplateColumns: '130px auto',
  gridTemplateRows: 'auto',
  gap: '0px 24px',
});

const SeverityLevel = styled(Chip)<{ severity: TSeverityLevel }>(({ theme, severity }) => ({
  backgroundColor: theme.severity[severity],
  color: 'white',
  fontWeight: 500,
  width: 'fit-content',
}));

const StyledOutlinedInput = styled(OutlinedInput)({
  borderRadius: '10px',
  height: '40px',
  width: '50%',
});

const Label = styled(Typography)<{ requiredmarker?: string }>(({ theme, requiredmarker }) => ({
  fontWeight: 500,
  alignSelf: 'center',

  '&::after': {
    content: requiredmarker,
    color: theme.palette.error.main,
  },
}));

const EmptyHistoryBlock = styled(Typography)`
  color: #bcbcbc;
  text-align: center;
  font-size: 32px;
  font-weight: 400;
  line-height: 42px;
  letter-spacing: 0.25px;
  margin-top: 30%;
`;

interface IMaintenanceHistoryProps {
  tabPanelHeight: number;
}

const MaintenanceHistory: React.FC<IMaintenanceHistoryProps> = ({ tabPanelHeight }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const dispatch = useDispatch();
  const issueDetails = useSelector(selectIssueDetails);
  const issueCauses = useSelector(selectIssueCauses);
  const headlineRef = useRef<HTMLDivElement>(null);
  const sortFilterRef = useRef<HTMLDivElement>(null);

  const [expanded, setExpanded] = useState<string[]>([]);
  const [editMode, setEditMode] = useState<string | false>(false);
  const [issueCause, setIssueCause] = useState<string | null>(null);
  const [severityLevel, setSeverityLevel] = useState<string | null>(null);
  const [comment, setComment] = useState<string | null>(null);

  // calc paper height with tab, headline and sort & filter height and 120px for margin and padding
  const paperHeight =
    tabPanelHeight - (headlineRef.current?.offsetHeight || 0) - (sortFilterRef.current?.offsetHeight || 0) - 120;
  const severityOptions = [
    { key: SEVERITY_LEVEL.LOW, label: t(`issues:severity:${SEVERITY_LEVEL.LOW}`) },
    { key: SEVERITY_LEVEL.MEDIUM, label: t(`issues:severity:${SEVERITY_LEVEL.MEDIUM}`) },
    { key: SEVERITY_LEVEL.HIGH, label: t(`issues:severity:${SEVERITY_LEVEL.HIGH}`) },
  ];

  useEffect(() => {
    dispatch(fetchIssueFeedbacksPending());
  }, [dispatch]);

  const handleChange = (panelId: string) => (_event: React.SyntheticEvent, _isExpanded: boolean) => {
    if (expanded.includes(panelId)) {
      setExpanded(expanded.filter((id) => id !== panelId));
    } else {
      setExpanded((prevExpanded) => [...prevExpanded, panelId]);
    }
  };

  const getCauseTitle = (issueCause: TIssueCause) => {
    switch (language) {
      case 'en':
        return issueCause.causeEn;
      case 'de':
        return issueCause.causeDe;
      default:
        return issueCause.causeEn;
    }
  };

  const clearStates = () => {
    setIssueCause(null);
    setSeverityLevel(null);
    setComment(null);
  };

  const handleEditMode = (e: React.MouseEvent, feedback: TIssueFeedback) => {
    e.stopPropagation();

    setEditMode(feedback.id);
    setIssueCause(feedback.issueCauseId);
    setSeverityLevel(feedback.severityLevel);
    setComment(feedback.comment);
  };

  const handleCancelEdit = (e: React.MouseEvent) => {
    e.stopPropagation();
    clearStates();
    setEditMode(false);
  };

  const handleUpdateFeedback = (e: React.MouseEvent, feedback: TIssueFeedback) => {
    e.stopPropagation();

    if (!issueCause || !severityLevel || !comment) {
      return;
    }

    dispatch(
      updateIssueFeedbacksPending({
        id: feedback.id,
        issueId: feedback.issueId,
        issueCauseId: issueCause,
        severityLevel,
        comment,
        otherCause: feedback.otherCause,
        ignoreIssue: feedback.ignoreIssue,
      }),
    );

    clearStates();
    setEditMode(false);
  };

  const createChartData: (issueFeedback: TIssueFeedback) => TChartData = (feedback) => {
    let chartData: TChartData = {
      ts: [],
      acc_z: [],
    };

    const eventForFeedback = issueDetails?.measurementEvents
      .filter((event) => !dayjs(event.measurementEventDateTime).isAfter(feedback.createdAt, 'day'))
      .sort(
        (a, b) => new Date(b.measurementEventDateTime).getTime() - new Date(a.measurementEventDateTime).getTime(),
      )[0];

    if (eventForFeedback?.eventData?.[0]?.rawData) {
      chartData = {
        ...(omit(JSON.parse(eventForFeedback?.eventData[0]?.rawData), ['serialnumber', 'mountposition']) as TChartData),
      };
    }

    return chartData;
  };

  const getChartData = (chartData: TChartData) => {
    // remove timestring from data
    const filteredData = omit(chartData, ['ts']);

    // convert values from mili to g
    for (const [key, value] of Object.entries(filteredData)) {
      filteredData[key] = value.map((value: number) => value / 1000);
    }

    return filteredData;
  };

  return (
    <>
      <h3 ref={headlineRef}>{t('issues:detailedView:maintenanceHistory:title')}</h3>
      <Box ref={sortFilterRef} sx={{ display: 'flex', gap: '16px', alignItems: 'center', marginBottom: '16px' }}>
        <MaintenanceHistorySort />
        <MaintenanceHistoryFilter />
      </Box>
      <Box sx={{ height: paperHeight, overflow: 'auto', padding: '2px' }}>
        {!issueDetails?.issueFeedbacks.length && (
          <EmptyHistoryBlock>{t('issues:detailedView:maintenanceHistory:noFeedbacks')}</EmptyHistoryBlock>
        )}
        {issueDetails?.issueFeedbacks?.map((feedback) => (
          <Accordion
            key={feedback.id}
            expanded={expanded.includes(feedback.id)}
            onChange={handleChange(feedback.id)}
            data-testid='accordion'
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`${feedback.id}-content`}
              id={`${feedback.id}-header`}
            >
              <Typography sx={{ width: '33%', flexShrink: 0, fontWeight: 500 }}>
                {getCauseTitle(feedback.issueCause)}
              </Typography>
              <Typography sx={{ width: '33%', flexShrink: 0 }}>{getFormattedDate(feedback.createdAt)}</Typography>
              {editMode !== feedback.id && expanded.includes(feedback.id) && (
                <Link
                  component='button'
                  onClick={(e) => handleEditMode(e, feedback)}
                  underline='none'
                  variant='subtitle1'
                  sx={editMaintenance}
                >
                  {t('common:edit')}
                </Link>
              )}
              {editMode === feedback.id && (
                <Box sx={editMaintenance}>
                  <Link component='button' onClick={handleCancelEdit} underline='none' variant='subtitle1'>
                    {t('common:cancel')}
                  </Link>
                  <Link
                    component='button'
                    onClick={(e) => handleUpdateFeedback(e, feedback)}
                    underline='none'
                    variant='subtitle1'
                  >
                    {t('common:save')}
                  </Link>
                </Box>
              )}
            </AccordionSummary>
            <AccordionDetails sx={{ display: 'flex', gap: '32px' }}>
              <Detail>
                <GridContainer>
                  <GridItem sx={{ gridTemplateColumns: editMode === feedback.id ? '100px auto' : '140px auto' }}>
                    <Label requiredmarker={editMode === feedback.id ? '"*"' : '""'}>
                      {t('issues:detailedView:maintenanceHistory:cause')}
                    </Label>
                    {editMode !== feedback.id && <Typography>{getCauseTitle(feedback.issueCause)}</Typography>}
                    {editMode === feedback.id && (
                      <Select
                        value={issueCause}
                        onChange={(e) => setIssueCause(e.target.value)}
                        input={<StyledOutlinedInput />}
                      >
                        {issueCauses.map((opt, index) => (
                          <MenuItem key={opt.id} value={opt.id} data-testid={`cause-option-${index}`}>
                            {opt[language === 'en' ? 'causeEn' : 'causeDe']}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  </GridItem>
                  <GridItem sx={{ gridTemplateColumns: editMode === feedback.id ? '100px auto' : '140px auto' }}>
                    <Label requiredmarker={editMode === feedback.id ? '"*"' : '""'}>
                      {t('issues:detailedView:maintenanceHistory:severity')}
                    </Label>
                    {editMode !== feedback.id && (
                      <SeverityLevel label={feedback.severityLevel} severity={feedback.severityLevel} />
                    )}
                    {editMode === feedback.id && (
                      <Select
                        value={severityLevel}
                        disabled={feedback.ignoreIssue}
                        onChange={(e) => setSeverityLevel(e.target.value)}
                        input={<StyledOutlinedInput />}
                      >
                        {severityOptions.map((opt, index) => (
                          <MenuItem key={opt.key} value={opt.key} data-testid={`severity-option-${index}`}>
                            {opt.label}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  </GridItem>
                  <GridItem sx={{ gridTemplateColumns: editMode === feedback.id ? '1fr' : '140px auto' }}>
                    <Label sx={{ alignSelf: 'start' }}>{t('issues:detailedView:maintenanceHistory:additional')}</Label>
                    {editMode !== feedback.id && <Typography>{feedback.comment}</Typography>}
                    {editMode === feedback.id && (
                      <TextField
                        size='small'
                        multiline
                        rows={5}
                        fullWidth
                        onChange={(e) => setComment(e.target.value)}
                        value={comment}
                      />
                    )}
                  </GridItem>
                </GridContainer>
              </Detail>
              <Detail>
                <Chart
                  title={t('issues:detailedView:overview:chart:title')}
                  labels={createChartData(feedback).ts}
                  data={getChartData(createChartData(feedback))}
                  hiddenDatasets={['acc_x', 'acc_y', 'acc_z']}
                  xAxisLabel={t('issues:detailedView:overview:chart:xLabel')}
                  yAxisLabel={t('issues:detailedView:overview:chart:yLabel')}
                  width={400}
                />
              </Detail>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </>
  );
};

export default MaintenanceHistory;
